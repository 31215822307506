import React from 'react'
import { Platform, View, Image, Text, ImageBackground, StyleSheet, SafeAreaView, ScrollView, KeyboardAvoidingView } from 'react-native'
import { theme } from '../core/theme'
import FontAwesome from '@expo/vector-icons/FontAwesome'

// let BgImage;
// switch (Platform.OS) {
//   case 'android':
//     BgImage = require('../assets/imgs/bg/QHD.jpg');
//     break;
//   case 'ios':
//     BgImage = require('../assets/imgs/bg/QHD.jpg');
//     break;

//   default:
//     BgImage = require('../assets/imgs/bg/FULLHD.jpg');
//     break;
// }

export default function Background({ children, ...props }) {
  return (
    <>
      {/*<ImageBackground
        source={props.isVisibleBg?"":BgImage}
        style={styles.background}
      >*/}
        <ScrollView>
          <KeyboardAvoidingView style={styles.container} behavior="padding">
          {/* <ScrollView showsVerticalScrollIndicator={true} > */}
          {/* <ScrollView contentInsetAdjustmentBehavior="automatic" > */}
            {/* <SafeAreaView> */}
              {children}
            {/* </SafeAreaView> */}
          </KeyboardAvoidingView>
        </ScrollView>

        <View style={{justifyContent:'center', backgroundColor: theme.colors.secondary, height: 50, width: '100%', textAlign:'center', bottom:0}}>
          <Text style={{paddingBottom:10}}>
            <FontAwesome name="copyright"></FontAwesome> 2023 by PT. Sinergi Prakarsa Utama. Powered by Sinergi Apps
            <Image source={require('../assets/imgs/logo/adaptive-icon.png')} style={styles.image} />
          </Text>
        </View>
     {/* </ImageBackground>*/}
      
    </>
  )
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    resizeMode: 'cover', // or 'stretch'
    // position: 'fixed',
    // height: '100%',
    // width: '100%',
    // paddingBottom:50,
  },
  container: {
    flex: 1,
    padding: 10,
    width: '100%',
  },
  image: {
    width: 25,
    height: 25,
    justifyContent:'center',
    textAlign:'center',
    top:8,
  },
})
