import React, { useState, useRef, useEffect, useContext } from 'react'
import { Card, DataTable } from 'react-native-paper'
import { View, Text, ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import axios from 'axios'
import Background from '../../components/Background'
import ReportParams from '../../components/mdashboard/ReportParams'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
// import "jszip/dist/jszip.min.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import $ from "jquery";

const jzip = require( 'jszip/dist/jszip.js');
window.JSZip = jzip;

import {
  DialogCostum
} from '../../components'

import { LoginContext } from '../../contexts/GlobalState'
import { currencyFormat } from '../../utils/currency';

export default function SPRP0005({ route, navigation }) {
   $.DataTable = require('datatables.net')
  const tableRef = useRef()
  const tableName = "table"+route.name

  const homeprm = navigation.getState();
  const home = homeprm.routes[0].state.routes[0].params;
  let table;

  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [dataGrid, setDataGrid] = useState()
  const [office, setOffice] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()

  const hideDialog = () => {
    setVisible(false)
  }

  const exportTo = (exp) =>{
    if(exp==='EXCEL'){
      $( `#${tableName}_wrapper` ).find('.buttons-excel').trigger('click');
    }
    if(exp==='PDF'){
      $( `#${tableName}_wrapper` ).find('.buttons-pdf').trigger('click');
    }
  }

  const date = new Date(home.oprdt)
  const bln = date.getMonth();
    var val;
    if(bln<3){
      val=12;
    }else if(bln>2 && bln<6){
      val=3;
    }else if(bln>5 && bln<9){
      val=6;
    }else if(bln>8 && bln<12){
      val=9;
    }
    var years;
    if(val==12){
      years = date.getFullYear()-1;
    }else{
      years = date.getFullYear();
    }
    const cek = new Date(years+"-"+val+"-01");

  const login = useContext(LoginContext)  
  useEffect(() => {
    // setTimeout( function () {
    //   $(`#${tableName}`).DataTable().columns.adjust();
    // }, 500) 
  }, [login]);
  useEffect(() => {
    const months = listBulan(val-1);
    setMonth(months.month.label)
    setYear(years)
    setOffice(home.brncd+' - '+home.brnm);
    postSubmit({
      brnm:home.brnm,
      office:{value: home.brncd},
      month:{value: val-1},
      year:{value: years},
    })
  }, []);

  const postSubmit = (v) => {
    try {
      axios.post(process.env.REACT_APP_URL_API + "SPRP0005/read",  {
        method:"getreport",
        office:v.office.value,
        month:v.month.value,
        year:v.year.value,
      }, {timeout: 120000})
      .then(res => {
        console.log(res);
        if (!res.data.data){
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
          setVisible(true);
        }else{
          setOffice(v.office.value+' - '+v.brnm)
          const months = listBulan(v.month.value)
          setMonth(months.month.label)
          setYear(v.year.value)
          setDataGrid(res.data.data)

          const title = 'LAPORAN PUBLIKASI TRIWULAN'+'\n LAPORAN LABA RUGI \n'+ login.bankname+'\n'+ v.office.value+' - '+v.brnm.toUpperCase()+'\n'+ months.month.label+' '+v.year.value

          table = $(`#${tableName}`).DataTable(
            {
              data: res.data.data,
              scrollY:"360px",
              scrollX:true,
              scrollCollapse: true,
              fixedHeader: true,
              dom: "lBfrtip",
              buttons: [
                {
                  extend: "excelHtml5",
                  title: title,
                  // messageTop: ,
                },
                {
                  extend: 'pdfHtml5',
                  pageSize: 'LEGAL',
                  title: title,
                  // messageTop: ,
                  customize: function (doc) { 
                    doc.styles.title = {
                      fontSize: '12',
                      alignment: 'center'
                    }   
                    var rowCount = doc.content[1].table.body;
                    for (let i = 1; i < rowCount.length; i++) {
                      rowCount[i][1].alignment = 'right';
                      rowCount[i][2].alignment = 'right';
                    }
                  }
                },
              ],
              columnDefs: [
                { targets: [1,2], className: 'dt-body-right' },
                { targets: [1,2], className: "dt-head-right" }
              ],
              columns: [
                // { title: "NO"},
                { title: "POS"},
                { title: "POSISI TANGGAL LAPORAN"},
                { title: "POSISI YANG SAMA TAHUN LALU"},
              ],
              oLanguage:{
                sSearch:"<span>Pencarian </span> _INPUT_",
                sLengthMenu:"Tampil _MENU_",
                sZeroRecords:"Tidak ada data untuk ditampilkan.",
                sInfo:"Total _TOTAL_ baris (_START_ - _END_)",
                sInfoFiltered: "", 
                sInfoEmpty: "Menampilkan 0 baris",
                oPaginate:{
                  sNext:">",
                  sPrevious:"<"
                }
              },
              searching:{
                regex:1
              },
              ordering: false,
              pageLength: -1,
              lengthMenu: [ [10, 25, 50, 100, -1], [10, 25, 50, 100, "Semua"] ],
              destroy: true,  // I think some clean up is happening here
            }
          )
          
          $('.dt-buttons').hide();
        // Extra step to do extra clean-up.
          return function() {
              console.log("Table destroyed")
              table.destroy()
          }
        }
      })
      .catch(err => {
        console.log(err);
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
        setVisible(true);
      });
    } catch (err) {
      console.log(err);
      setErrorMessage('Server Luring');
      setVisible(true);
    }
  }

  //untuk isi list bulan di pilihan parameter
  const bulan = listBulan(bln);
  return (
    <Background isVisibleBg={true}>
      <ReportParams home={{oprdt:cek, brncd:home.brncd}} export={exportTo} action={postSubmit} progid={route.name} monthlist={bulan.list}/>
      <Card style={{backgroundColor: 'rgba(204, 204, 255, 0.2)'}}>
        <Card.Content>
          <View style={{alignItems:'center', padding:5}}>
            <Text style={{fontSize:14, fontWeight:'bold'}}>LAPORAN PUBLIKASI TRIWULAN</Text>
            <Text style={{fontSize:14, fontWeight:'bold'}}>LAPORAN LABA RUGI</Text>
            <Text style={{fontSize:14, fontWeight:'bold'}}>{login.bankname}</Text>
            <Text style={{fontSize:14, fontWeight:'bold', textTransform: 'uppercase'}}>{office}</Text>
            <Text style={{fontSize:14, fontWeight:'bold'}}>{month} {year}</Text>
          </View>
          <table
            style={{
              fontSize: '14px',
              fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif"
            }}
            className="display"
            width="100%"
            id={tableName}
            ref={ tableRef }
          >
          </table>
        </Card.Content>
      </Card>
      <DialogCostum props={{visible, hideDialog, message:errorMessage}} />
    </Background>
  )
}

function listBulan(bln) {
    const monthList = [
      { label: "MARET", value: 2 },
      { label: "JUNI", value: 5 },
      { label: "SEPTEMBER", value: 8 },
      { label: "DESEMBER", value: 11 },
    ];

    var month;
    for (let i = 0; i < monthList.length; i++) {
      if(monthList[i].value === bln){
        month = monthList[i];
        break;
      }
    }
    return {
        list:monthList,
        month,
    }
}