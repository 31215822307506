import numeral from 'numeral';

export function currencyAbbreviate(number) {
  
    var SI_SYMBOL = ["", "RB", "JT", "M", "T", "P", "E"];
    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(2) + (suffix===undefined?'':suffix);
}

export function currencyFormat(number) {
    return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
  }