import React, { useState } from 'react'
import { ScrollView, View, StyleSheet, KeyboardAvoidingView } from "react-native"
import { Divider, Card, Button, TextInput as Input } from 'react-native-paper'
import axios from 'axios'
import { Picker } from '@react-native-picker/picker'
import DropDown from "react-native-paper-dropdown"

import { theme } from '../../core/theme'
import {
  TextInput,
  DialogCostum,
  Loader,
} from '../../components'
import Background from '../../components/Background'

export default function SPRP0016({ route, navigation }) {
  const homeprm = navigation.getState();
  const home = homeprm.routes[0].state.routes[0].params;
    //const date = new Date(home.oprdt)
  const date = new Date() // 24/01/2024 read date current not oprdt
  const [errorMessage, setErrorMessage] = useState(null)
  const [visible, setVisible] = useState(false)
  const [spin, setSpin] = useState()
  
  const hideDialog = () => {
    setVisible(false)
  }

  const [year, setYear] = useState({ value: date.getFullYear(), error: '' })
  const [jangka, setJangka] = useState({ value: '5', error: '' })
  const [metode, setMetode] = useState({ value: 'SPRP0016A', error: '' })
  const [format, setFormat] = useState({ value: 'xlsx', error: '' })
  const [jangkaShow, setJangkaShow] = useState(true);
  const [formats, setFormats] = useState({
    list:[
      { label: "Excel", value: 'xlsx' },
      { label: "CSV", value: 'csv' },
    ]
  });
  
  const [metodeDropDown, setMetodeDropDown] = useState(false);
  const [formatDropDown, setFormatDropDown] = useState(false);
  const metodes = { 
    list:[
      { label: "LGD Expected Recoveries", value: 'SPRP0016A' },
      { label: "LGD Collateral Shortfall", value: 'SPRP0016B' },
      { label: "LGD Weighted Average", value: 'SPRP0016C' },
    ]
  }

  // const formats = { 
  //   list:[
  //     { label: "Excel", value: 'xlsx' },
  //     { label: "CSV", value: 'csv' },
  //   ]
  // }

  const yearValidator = (v) => {
    if (!v) return "Periode Tahun tidak boleh kosong."
    if (parseInt(v)<1000 || parseInt(v)>9999 || isNaN(parseInt(v))) return "Periode Tahun tidak valid."
    if (v>date.getFullYear()) return "Periode Tahun tidak boleh lebih besar dari "+date.getFullYear()+"."
    return ''
  }

  const jangkaValidator = (v) => {
    if (!v && v!==0 && visible) return "Jangka Waktu (Tahun) tidak boleh kosong."
    // if (v<5) return "Jangka Waktu (Tahun) tidak boleh lebih kecil dari 5."
    return ''
  }

  const formatValidator = (v) => {
    if (!v && v!==0) return "Cetak Ke tidak boleh kosong."
    return ''
  }

  const metodeValidator = (v) => {
    if (!v) return "Metode PD tidak boleh kosong."
    return ''
  }

  const onCancel = () => {    
    setYear({ value: date.getFullYear(), error: '' })
    setJangka({ value: '5', error: '' })
    setMetode({ value: 'SPRP0016A', error: '' })
    setJangkaShow(true);
    setFormats({
      list:[
        { label: "Excel", value: 'xlsx' },
        { label: "CSV", value: 'csv' },
      ]
    });
  }

  const onSubmit = async () => {
    const yearError = yearValidator(year.value)
    const jangkaError = jangkaValidator(jangka.value)
    const metodeError = metodeValidator(metode.value)
    const formatError = formatValidator(format.value)

    setYear({ ...year, error: yearError })
    setJangka({ ...jangka, error: jangkaError })
    setMetode({ ...metode, error: metodeError })
    setFormat({ ...format, error: formatError })
    if (yearError || jangkaError || metodeError || formatError) {
      return
    }

    setSpin(true)
    
    try {
      await axios.post(process.env.REACT_APP_URL_API + "SPRP0016/read",  {
        year:year.value,
        jangka:jangka.value,
        format:format.value,
        method:metode.value
      }, {timeout: 1800000})
      .then(res => {
        
        setSpin(false)

        if (!res.data.data){
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
          setVisible(true);
        }else{
          var linkSource = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ res.data?.data?.content;
          var downloadLink = document.createElement("a");

          downloadLink.href = linkSource;
          downloadLink.download = res.data?.data?.filenm;
          downloadLink.click();
          
          if(res.data?.data?.path) window.location.href = process.env.REACT_APP_URL_API + res.data.data.path;
        }
      })
      .catch(err => {
        setSpin(false)
        console.log(err);
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
        setVisible(true);
      });
    } catch (err) {
      setSpin(false)
      console.log(err);
      setErrorMessage('Server Luring');
      setVisible(true);
    }

  }

  const onChangeMetode = (v) => {
    setMetode({ value: v, error: '' });
    if (v == 'SPRP0016A') {
      setJangkaShow(true);
      setJangka({value: '5'});
      setFormats({
        list:[
          { label: "Excel", value: 'xlsx' },
          { label: "CSV", value: 'csv' },
        ]
      });
    } else {
      setJangkaShow(false);
      setJangka({value: ''});
      setFormats({
        list:[
          { label: "Excel", value: 'xlsx' },
        ]
      });
    } 
  }

  return (
    <Background>
      <ScrollView>
        <KeyboardAvoidingView style={styles.container} behavior="padding">
          <View>
            
            <Card style={styles.gridView}>
              <Card.Title title="Parameter" />
              <Divider />
              <Card.Content>
                <View>
                  <View style={styles.dropDown}>
                    <DropDown
                      label={"Metode PD"}
                      mode={"outlined"}
                      value={metode.value}
                      setValue={(text) => onChangeMetode(text)}
                      list={metodes.list}
                      error={!!metode.error}
                      errorText={metode.error}
                      visible={metodeDropDown}
                      showDropDown={() => setMetodeDropDown(true)}
                      onDismiss={() => setMetodeDropDown(false)}
                      inputProps={{
                        right: <Input.Icon name={metodeDropDown?"menu-up":"menu-down"} />,
                      }}
                    />
                    {metode.error ? <Text style={styles.error}>{metode.error}</Text> : null}
                  </View>
                  {jangkaShow && <TextInput
                    label="Jangka Waktu (Tahun)"
                    returnKeyType="next"
                    value={jangka.value}
                    onChangeText={(text) => setJangka({ value: text.replace(/[^0-9]/g, ''), error: '' })}
                    errorText={jangka.error}
                  />}
                  <TextInput
                    label="Periode Tahun"
                    returnKeyType="next"
                    value={year.value}
                    onChangeText={(text) => setYear({ value: text.replace(/[^0-9]/g, ''), error: '' })}
                    // error={!!year.error}
                    errorText={year.error}
                  />
                  <View style={styles.dropDown}>
                    <DropDown
                      label={"Cetak Ke"}
                      mode={"outlined"}
                      value={format.value}
                      setValue={(text) => setFormat({ value: text, error: '' })}
                      list={formats.list}
                      error={!!format.error}
                      errorText={format.error}
                      visible={formatDropDown}
                      showDropDown={() => setFormatDropDown(true)}
                      onDismiss={() => setFormatDropDown(false)}
                      inputProps={{
                        right: <Input.Icon name={formatDropDown?"menu-up":"menu-down"} />,
                      }}
                    />
                    {format.error ? <Text style={styles.error}>{format.error}</Text> : null}
                  </View>
                </View>
              </Card.Content>
              <Divider />
              <Card.Actions style={{justifyContent: 'right'}}>
                <Button onPress={onCancel}>Batal</Button>
                <Button onPress={onSubmit}>Cetak</Button>
              </Card.Actions>
            </Card>
            
          </View>
        </KeyboardAvoidingView>
      </ScrollView>

      { spin && <Loader size="large" animating={spin} />}
      <DialogCostum props={{visible, hideDialog, message:errorMessage}} />
    </Background>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 50,
    padding: 10,
    maxWidth: 340,
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridView: {
    marginTop: 10,
  },
  error: {
    fontSize: 13,
    color: theme.colors.error,
    paddingTop: 8,
  },
  dropDown: {
    marginVertical: 12,
  },
});
