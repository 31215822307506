import React, { useState } from 'react'
import { ScrollView, View, StyleSheet, KeyboardAvoidingView } from "react-native"
import { Divider, Card, Button } from 'react-native-paper'
import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Paragraph from '../components/Paragraph'

import { theme } from '../core/theme'
import {
  Background,
  DialogCostum,
} from '../components'

export default function LogoutScreen({ navigation }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [visible, setVisible] = useState(false);
  
  const hideDialog = () => {
    setVisible(false)
    navigation.goBack()
  };

  const outDialog = async () => {
    try {
      await axios.post( process.env.REACT_APP_URL_API + "webarb/login/logout",  {code_apps:'SP'}, {timeout: 120000})
      .then(res => {
        console.log(res);

        if (res.data.data){
          setVisible(false)
          AsyncStorage.clear() //all items
          navigation.reset({
            index: 0,
            routes: [{ name: 'Sinergi Apps' }],
          })
        }else{
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
        }

      })
      .catch(err => {
        console.log(err);
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
      });
    } catch (err) {
      console.log(err);
      setErrorMessage('Server Luring');
    }
  }

  const button = (
    <>
      <Button onPress={errorMessage?hideDialog:outDialog}>Ya</Button>
      {errorMessage?"":<Button onPress={hideDialog}>Tidak</Button>}
    </>
  )

  return (
    <Background>
      <ScrollView>
        <KeyboardAvoidingView style={styles.container} behavior="padding">
          <View>
            
            <Card style={styles.gridView}>
              <Card.Content>
                <View>
                  <Paragraph>Keluar Dari Aplikasi?</Paragraph>
                </View>
              </Card.Content>
              <Divider />
              <Card.Actions style={{justifyContent: 'right'}}>
                {button}
              </Card.Actions>
            </Card>
            
          </View>
        </KeyboardAvoidingView>
      </ScrollView>

      <DialogCostum props={{visible, hideDialog, message:errorMessage}} />
    </Background>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 50,
    padding: 10,
    maxWidth: 340,
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridView: {
    marginTop: 10,
  },
  error: {
    fontSize: 13,
    color: theme.colors.error,
    paddingTop: 8,
  },
});